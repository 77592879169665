import React, { useContext, useState } from "react";
import { Breadcrumb, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import SunsetBeachVillaSlides from "./SunsetBeachVillaSlides";
import VillasSignatures from "../VillasSignatures";
import VillasAmenities from "../VillasAmenities";
import OtherVillas from "../OtherVillas";
import bannerCover from "../../assets/defaultBannerAndIcon/banner-placehoder.jpg";
import userIcon from "../../assets/images/user.svg";
import bedIcon from "../../assets/images/bed.svg";
import poolIcon from "../../assets/images/swimming-pool.svg";
import areaIcon from "../../assets/images/area.svg";
import Modals from "../../components/Modals";
import { useQuery } from "react-query";
import { getAllVillas, getSingleVilla } from "../../services/dataServices";
import Helmet from "../../components/Helmet";
import BannerImage from "../../components/BannerImage";
import PageNotFound from "../../components/PageNotFound";
// import { MyContext } from "../../Contex/LoadingContex";
// import Loader from "../../components/Loader";

function VillaDetails({ setErrorPage, slugOfOurVillas }) {
  const { propertyId } = useParams();
  const [isBannerLoading, setIsBannerLoading] = useState(true);
  // const { setIsLoadingMain } = useContext(MyContext);
  const bookNowText = "Book now";
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function generateOccupancyText(property_occupancy) {
    let textArray = [];
    for (let i = 0; i < property_occupancy.length; i++) {
      let occu = property_occupancy[i];
      let adultsText = "";
      let childrenText = "";

      // Handling adults count text
      if (occu.adult_count === 1) {
        adultsText = `${occu.adult_count} adult`;
      } else {
        adultsText = `${occu.adult_count} adults`;
      }

      // Handling children count text
      if (occu.child_count !== 0) {
        if (occu.child_count === 1) {
          childrenText = `${occu.child_count} child`;
        } else {
          childrenText = `${occu.child_count} children`;
        }
        textArray.push(`${adultsText} + ${childrenText}`);
      } else {
        textArray.push(adultsText); // If there are no children, only add adults count
      }

      if (i !== property_occupancy.length - 1) {
        textArray[textArray.length - 1] += " or ";
      }
    }

    return textArray;
  }

  const singleVillaData = useQuery(
    ["getSingleVilla", propertyId],
    () => getSingleVilla(propertyId),
    {
      refetchIntervalInBackground: true,
    }
  );

  const AllVillas = useQuery("villalist", getAllVillas);

  if (singleVillaData?.data?.data === 404) {
    return (
      <>
        <PageNotFound setErrorPage={setErrorPage} />
      </>
    );
  }

  const keywords = singleVillaData?.data?.data?.property_data[0]?.keywords;
  const slug = singleVillaData?.data?.data?.property_data[0]?.slug;
  const meta_title = singleVillaData?.data?.data?.property_data[0]?.meta_title;
  const meta_description =
    singleVillaData?.data?.data?.property_data[0]?.meta_description;

  const VillaHeading =
    singleVillaData?.data?.data?.property_data[0]?.property_name;
  const InnerBannerImage =
    singleVillaData?.data?.data?.property_data[0]?.inner_banner_image;

  const InnerBannerImageAltTag =
    singleVillaData?.data?.data?.property_data[0]?.inner_banner_alt_tag;

  const unitPlanPath =
    singleVillaData?.data?.data?.property_data[0]?.unit_plan_path;
  const VillaDeatails =
    singleVillaData?.data?.data?.property_data[0]?.property_description;
  const totalAreaSqm = singleVillaData?.data?.data?.property_data[0]?.area_sqm;
  const poolSize =
    singleVillaData?.data?.data?.property_data[0]?.private_pool_size;

  const thumbImageData = singleVillaData?.data?.data?.images_data.map(
    (imageData) => ({
      thumbImageUrl: imageData?.image_path,
      imgAltTag: imageData?.property_image_alt_tag,
    })
  );
  const ImageData = singleVillaData?.data?.data?.images_data.map(
    (imageData) => ({
      imageUrl: imageData?.image_path,
      imgAltTag: imageData?.property_image_alt_tag,
    })
  );

  const tagline = singleVillaData?.data?.data?.property_data[0]?.tag_line;
  let userAdultData = "";
  if (singleVillaData?.data?.data?.property_occupancy_data) {
    userAdultData = generateOccupancyText(
      singleVillaData?.data?.data?.property_occupancy_data
    );
  }
  const featuresData = singleVillaData?.data?.data?.sign_features_data.map(
    (featured) => featured
  );
  const amenityData = singleVillaData?.data?.data?.amenity_data.map(
    (featured) => featured
  );

  const othersVillaSettings = {
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 6000,
    slidesToShow:
      AllVillas?.data?.data?.length - 1 < 3
        ? AllVillas?.data?.data?.length - 1
        : 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const beachSliderLargeSettings = {
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const beachSliderThumbSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 6000,
    slidesToScroll: 1,
    vertical: true,
    centerMode: false,
    slidesToShow:
      thumbImageData != null
        ? thumbImageData.length > 4
          ? 4
          : thumbImageData.length
        : 0,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          vertical: false,
          slidesToShow:
            thumbImageData != null
              ? thumbImageData.length > 4
                ? 4
                : thumbImageData.length
              : 0,
        },
      },
      {
        breakpoint: 768,
        settings: {
          vertical: false,
          slidesToShow:
            thumbImageData != null
              ? thumbImageData.length > 3
                ? 3
                : thumbImageData.length
              : 0,
        },
      },
      {
        breakpoint: 476,
        settings: {
          vertical: false,
          slidesToShow:
            thumbImageData != null
              ? thumbImageData.length > 2
                ? 2
                : thumbImageData.length
              : 0,
        },
      },
    ],
  };
  // const handleImageLoad = () => {
  //   document.body.classList.remove("slider-open");
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // const handleError = () => {
  //   document.body.classList.remove("slider-open");
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // if (singleVillaData.isLoading) {
  //   setIsLoadingMain(true);
  //   document.body.classList.add("slider-open");
  //   return <Loader />;
  // } else if (isBannerLoading) {
  //   document.body.classList.add("slider-open");
  //   setIsLoadingMain(true);
  // }
  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100 cover-overlay">
            {InnerBannerImage !== null ? (
              // <img
              //   className="w-100 h-100"
              //   src={InnerBannerImage}
              //   title=""
              //   alt={InnerBannerImageAltTag}
              //   onError={handleError}
              //   onLoad={handleImageLoad}
              // />
              <BannerImage
                className="w-100 h-100"
                src={InnerBannerImage}
                title=""
                alt={InnerBannerImageAltTag}
                isDataIsLoading={singleVillaData?.isLoading}
              />
            ) : (
              <img
                className="w-100 h-100"
                src={bannerCover}
                title=""
                alt={InnerBannerImageAltTag}
              />
            )}
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                {VillaHeading}
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/${slugOfOurVillas}`}>Villas</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{VillaHeading}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>
      <section>
        <div className="villa-full-details section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7">
                <h2 className="georgia letter-spacing-2">{VillaHeading}</h2>
                {tagline ? (
                  <span className="fw-bold slogan-two georgia">
                    <em>{tagline}</em>
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-5 text-end">
                <div className="plan-villa site-visit-links">
                  {unitPlanPath ? (
                    <Link
                      to={unitPlanPath}
                      className="georgia btn theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme"
                      target="_blank"
                    >
                      Villa Plan{" "}
                      <FontAwesomeIcon className="ms-2" icon={faArrowUp} />
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-lg-5 mt-4">
              <SunsetBeachVillaSlides
                images={ImageData}
                thumbimages={thumbImageData}
                beachSliderLargeSettings={beachSliderLargeSettings}
                beachSliderThumbSettings={beachSliderThumbSettings}
              />
            </div>
            <div className="row">
              <div className="col-12">
                <div className="villa-details-veiw">
                  {VillaDeatails && VillaDeatails ? (
                    <div className="dashed-heading position-relative">
                      <h4 className="georgia letter-spacing-2 text-uppercase">
                        <span>Details</span>
                      </h4>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="villa-para mt-4">
                    <p>{VillaDeatails}</p>
                    <div className="vill-bottom-info d-flex align-items-center justify-content-between flex-wrap">
                      <ul className="list-unstyled m-0 p-0 d-flex align-items-center flex-wrap">
                        <li>
                          <span>
                            <img src={userIcon} alt="" title="" />
                          </span>
                          <span>{userAdultData}</span>
                        </li>
                        <li>
                          <span>
                            <img src={bedIcon} alt="" title="" />
                          </span>
                          <span style={{ display: "inline-block" }}>
                            {singleVillaData?.data?.data?.bed_data.map(
                              (bed, index) => (
                                <div
                                  key={index}
                                  style={{ display: "inline-block" }}
                                >
                                  {index > 0 && ", "}
                                  {bed.bed_count === 1
                                    ? `${bed.bed_count} ${bed.bed_type}`
                                    : `${bed.bed_count} ${bed.bed_type}s`}
                                </div>
                              )
                            )}
                          </span>
                        </li>
                        <li>
                          <span>
                            <img src={areaIcon} alt="" title="" />
                          </span>
                          <span className="me-2">
                            Total Area - {`${totalAreaSqm}m²`}
                          </span>
                        </li>
                        {poolSize !== null ? (
                          <li>
                            <span>
                              <img src={poolIcon} alt="" title="" />
                            </span>
                            <span className="me-2">
                              Pool Size - {`${poolSize}m²`}
                            </span>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                      <div className="book-now">
                        {/* book_now_villas_ga4 this class is for triggring the ga4 event */}
                        <Button
                          onClick={handleShow}
                          className="btn primary theme-btn fw-medium text-white text-uppercase border-theme book_now_villas_ga4"
                        >
                          {bookNowText}
                        </Button>
                        <Modals show={show} handleClose={handleClose} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* row */}
            {featuresData && featuresData.length > 0 ? (
              <div className="row pt-xl-4">
                <div className="col-12">
                  <div className="villa-details-veiw">
                    <div className="dashed-heading position-relative">
                      <h4 className="georgia letter-spacing-2 text-uppercase">
                        <span>SIGNATURE FEATURES</span>
                      </h4>
                    </div>
                    <div className="all-signatures d-flex flex-wrap">
                      {featuresData?.map((signature) => (
                        <VillasSignatures
                          key={signature.property_sign_feature_id}
                          signatureText={signature.sign_feature}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* row */}
            {amenityData && amenityData.length > 0 ? (
              <div className="row pt-xl-4">
                <div className="col-12">
                  <div className="villa-details-veiw">
                    <div className="dashed-heading position-relative">
                      <h4 className="georgia letter-spacing-2 text-uppercase">
                        <span>AMENITIES</span>
                      </h4>
                    </div>
                    <div className="all-signatures all-amenities d-flex flex-wrap">
                      {amenityData?.map((amenity) => (
                        <VillasAmenities
                          key={amenity.property_amenity_id}
                          amenityIcon={amenity.icon_path}
                          amenityText={amenity.amentity_name}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* row */}
          </div>
        </div>
      </section>

      <section>
        <div className="other-villa-cards section-padding pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hotel-heading-wrap d-flex align-items-center justify-content-between">
                  <h2 className="text-uppercase georgia letter-spacing-2">
                    OTHER VILLAS
                  </h2>
                  <Link
                    className="text-dark text-decoration-none view-all text-uppercase"
                    to={`/${slugOfOurVillas}`}
                  >
                    View All{" "}
                    <FontAwesomeIcon className="ms-3" icon={faAngleRight} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="row mt-lg-5 mt-4">
              <OtherVillas
                slugOfOurVillas={slugOfOurVillas}
                singleVillaData={singleVillaData}
                otherVillaView={AllVillas}
                othersVillaSettings={othersVillaSettings}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default VillaDetails;
