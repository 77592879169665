import React from "react";
import { motion } from "framer-motion";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "react-query";
import Image from "../../components/Image";
import { getAspectRatio } from "../../services/dataServices";
import { stripHtml } from "../../components/OfferSlider";
function OfferCard({
  offerImage,
  offerHeadingOne,
  offerSmallHeading,
  tabPara,
  offerHotelLocation,
  offerLink = [],
  bookLink = [],
  offerId,
  offerDicountText,
  slug,
  imgAltTag,
  slugOfOffers,
}) {
  function truncateAtLastWord(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }

    const truncatedText = text.slice(0, maxLength);

    // If there is no space in the truncated part, just return the original truncated tex
    return truncatedText;
  }
  function truncatepara(text, maxLength) {
    const strippedText=stripHtml(text)
    if (strippedText.length <= maxLength) {
      return strippedText;
    }

    const truncatedText = strippedText.slice(0, maxLength);

    // If there is no space in the truncated part, just return the original truncated tex
    return truncatedText;
  }
  const getWordCountValue = useQuery(
    ["AspectCount", "offer_word_count_in_card"],
    () => getAspectRatio("offer_word_count_in_card")
  );
  const wordCount =
    getWordCountValue &&
    getWordCountValue.data &&
    getWordCountValue.data.data.setting_value;

  return (
    <>
      <div className="tab-top-header position-relative">
        <div className="container">
          <div className="row position-relative">
            <div className="col-md-9">
              <motion.div
                key={Math.random()}
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="offer-img"
              >
                {/* <img className="w-100" src={offerImage} alt={imgAltTag} title="" /> */}
                <Image
                  className="w-100"
                  src={offerImage}
                  alt={imgAltTag}
                  title=""
                />
              </motion.div>
            </div>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              className="offer-info-card right-feature-text-col position-absolute top-0 bottom-0 m-auto d-flex align-items-center"
            >
              <div className="hotel-heading-wrap text-center right-feature-wrap bg-white">
                <span className="text-uppercase">{offerHotelLocation}</span>
                <h3 className="text-uppercase georgia heading-space mt-2">
                  {/* {offerHeadingOne} */}
                  {offerHeadingOne.length > wordCount ? (
                    <>
                      {parse(truncateAtLastWord(offerHeadingOne, wordCount))}

                      {" ...."}
                    </>
                  ) : (
                    parse(offerHeadingOne)
                  )}
                </h3>
                <span>{offerDicountText}</span>
                <p className="">
                  {tabPara.length > 100 ? (
                    <>
                      {parse(truncatepara(tabPara, 100))}

                      {" ...."}
                    </>
                  ) : (
                    truncatepara(tabPara)
                  )}
                  &nbsp;
                  <Link
                    to={`/${slugOfOffers}/${slug}`}
                    className="text-decoration-underline view-all"
                    title="Click here book now"
                  >
                    Learn More{" "}
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="ms-1 icon-font"
                    />
                  </Link>
                </p>
                <div className="offer-book-now">
                  {/* book_now_offers_ga4 added this class for ga4 event  */}
                  <Link
                    className="btn primary theme-btn fw-medium text-white text-uppercase border-theme btn btn-primary book_now_offers_ga4"
                    title="Click here book now"
                    to={bookLink}
                    target="_blank"
                  >
                    {"BOOK NOW"}{" "}
                  </Link>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OfferCard;
